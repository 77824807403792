import {
  buildMapToField,
  handleQueryResolve
} from '../utils';
import moment from "moment";

export default function (patientID) {
  if (patientID) {

      let attrs = [{
          param: 'PatientID',
          type: 'sql.Int',
          value: patientID // to support like
      }];

    let where = ['PatientId = @PatientId'];
    where.push('FillDate BETWEEN @fromDate');
    where.push('@toDate');

    // if (rxNumber) {
      //     attrs.push({
      //         param: 'rxNumber',
      //         type: 'sql.VarChar(50)',
      //         value: '%' + rxNumber + '%' // to support like
      //     });

      //     where.push('RXNumber LIKE @rxNumber');
      // }

      // if (genericName) {
      //     attrs.push({
      //         param: 'genericName',
      //         type: 'sql.VarChar(50)',
      //         value: '%' + genericName + '%' // to support like
      //     });

      //     where.push('GenericName LIKE @genericName');
      // }

      // if (brandName) {
      //     attrs.push({
      //         param: 'brandName',
      //         type: 'sql.VarChar(50)',
      //         value: '%' + brandName + '%' // to support like
      //     });

      //     where.push('BrandName LIKE @brandName');
      // }

      return this.query(`
        call "DBA".p_rpt_dosset_condensed(${patientID}, null); 
      `).then(handleQueryResolve)
        .then((records) => {
          return records.map(row => buildMapToField(row));
        });
  }

}